"use client";

import { usePathname, useSearchParams } from "next/navigation";
import { useState, useEffect } from "react";
import { TrackPageView } from "./TrackPageView.component";
type TrackPageViewContainerProps = {
  readonly referrer: string | null;
};
export function TrackPageViewContainer({
  referrer
}: TrackPageViewContainerProps) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [absoluteUrl, setAbsoluteUrl] = useState<string | null>(null);
  useEffect(() => {
    const origin = window.location.origin;
    const query = searchParams.size > 0 ? `?${searchParams.toString()}` : "";
    setAbsoluteUrl(`${origin}${pathname}${query}`);
  }, [pathname, searchParams]);
  return absoluteUrl !== null ? <TrackPageView currentURL={encodeURIComponent(absoluteUrl)} key="archive" referrer={referrer} /> : null;
}