import Script from "next/script";
export function Kilkaya() {
  // TODO (JPWM-20646): Kilkaya throws a preconnect error in Chrome
  return <>
      <Script id="Kilkaya" data-sentry-element="Script" data-sentry-source-file="Kilkaya.component.tsx">
        {`
 var k5aMeta = {
 "consent": 0,
 "url": "/"
 }
 `}
      </Script>
      <Script id="KilkayaModule" src="//cl-eu4.k5a.io/6408613675251d0be63b7112.module.js" type="module" data-sentry-element="Script" data-sentry-source-file="Kilkaya.component.tsx" />
      <Script id="KilkayaNoModule" src="//cl-eu4.k5a.io/6408613675251d0be63b7112.nomodule.js" async noModule data-sentry-element="Script" data-sentry-source-file="Kilkaya.component.tsx" />
    </>;
}