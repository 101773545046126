"use client";

import { useExponeaPageView } from "@/hooks/tracking/exponea/use-exponea-page-view-hook";
import { useGemiusTracking } from "@/hooks/tracking/gemius/use-gemius-tracking-hook";
import { useKilkayaPageView } from "@/hooks/tracking/kilkaya/use-kilkaya-tracking.hook";
import { useSnowplowPageViewEvent } from "@/hooks/tracking/snowplow/use-snowplow-page-view-event.hook";

type PageViewProps = {
  readonly referrer: string | null;
  readonly currentURL: string;
};

export function TrackPageView({ referrer, currentURL }: PageViewProps) {
  useSnowplowPageViewEvent();
  useGemiusTracking();
  useExponeaPageView(referrer);
  useKilkayaPageView(referrer, currentURL);
  return null;
}
