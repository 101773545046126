"use client";

import type { ReactNode } from "react";
import { useContext, createContext } from "react";
import { InitializeExponea } from "@/components/Tracking/exponea/InitializeExponea";
import { Kilkaya } from "@/components/Tracking/Kilkaya/Kilkaya.component";
import { InitializeLinkedInInsightTag } from "@/components/Tracking/LinkedIn/InitializeLinkedInInsightTag";
import { TrackPageViewContainer } from "@/components/Tracking/TrackPageViewContainer.component";
import type { TrackingDataModel } from "@/hooks/tracking/models/tracking-data-model.types";
const TrackingContext = createContext<TrackingDataModel | undefined>(undefined);
type TrackingProviderProps = {
  readonly model: TrackingDataModel;
  readonly children: ReactNode;
};
type UseTrackingContextReturn = {
  readonly trackingData: TrackingDataModel | null;
};
export function useTrackingData(): UseTrackingContextReturn {
  const trackingData = useContext(TrackingContext) ?? null;
  return {
    trackingData
  };
}
export function TrackingProvider({
  model,
  children
}: TrackingProviderProps) {
  return <TrackingContext.Provider value={model} data-sentry-element="unknown" data-sentry-component="TrackingProvider" data-sentry-source-file="tracking.context.tsx">
      <InitializeExponea trackingData={model} data-sentry-element="InitializeExponea" data-sentry-source-file="tracking.context.tsx" />
      <InitializeLinkedInInsightTag trackingData={model} data-sentry-element="InitializeLinkedInInsightTag" data-sentry-source-file="tracking.context.tsx" />
      <Kilkaya data-sentry-element="Kilkaya" data-sentry-source-file="tracking.context.tsx" />
      <TrackPageViewContainer referrer={model.referrer} data-sentry-element="TrackPageViewContainer" data-sentry-source-file="tracking.context.tsx" />
      {children}
    </TrackingContext.Provider>;
}