"use client";

import { useEffect, useState } from "react";
import { useTrackingData } from "@/contexts/tracking/tracking.context";
import { useUser } from "@/contexts/user/user.context";
import { useCookiebotConsent } from "@/hooks/cookiebot/use-cookiebot-consent.hook";
import { captureClientException } from "@/services/logging/capture-client-exception";
import { useJpmuid } from "../jpmuid/use-jpmuid.hook";
import { snowplowCommmonPayload } from "./snowplow-payload/snowplow-common-payload";

export function useSnowplowPageViewEvent() {
  const { trackingData } = useTrackingData();
  const consent = useCookiebotConsent();
  const { jpmuid } = useJpmuid();
  const { model: user } = useUser();

  const [isTracked, setIsTracked] = useState(false);

  useEffect(() => {
    async function trackPage() {
      if (navigator.userAgent.endsWith("CookieInformationScanner")) return;
      if (isTracked || !consent.submitted || trackingData === null) return;

      const { enableActivityTracking, trackPageView } = await import(
        /* webpackChunkName: "snowplow-tracker" */ "@snowplow/browser-tracker"
      );

      setIsTracked(true);
      const viewsRatio = 0.5;
      const hasHeartbeat: boolean = Math.random() < viewsRatio;
      const heartbeatTime = 30;

      if (hasHeartbeat) {
        enableActivityTracking({
          heartbeatDelay: heartbeatTime,
          minimumVisitLength: heartbeatTime,
        });
      }

      const commonContext = snowplowCommmonPayload(trackingData, jpmuid, user);

      const heartbeatContextCallback = () => {
        // eslint-disable-next-line functional/no-let -- need a new counter
        let count = -1;
        return () => {
          count += 1;
          return hasHeartbeat
            ? [
                {
                  data: {
                    count,
                    enabled_for_views_ratio: viewsRatio,
                    seconds_between_beats: heartbeatTime,
                  },
                  schema: "iglu:dk.jyllands-posten/heartbeat/jsonschema/1-0-1",
                },
              ]
            : [];
        };
      };

      trackPageView({
        context: commonContext,
        contextCallback: heartbeatContextCallback(),
      });
    }

    trackPage().catch(captureClientException);
  }, [isTracked, consent.submitted, jpmuid, trackingData]);
}
