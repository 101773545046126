import { useEffect } from "react";
import {
  archivePageLink,
  latestPageLink,
} from "@/components/page-links.constants";
import { useTrackingData } from "@/contexts/tracking/tracking.context";
import { useUser } from "@/contexts/user/user.context";
import { useCookiebotConsent } from "@/hooks/cookiebot/use-cookiebot-consent.hook";
import type { ConversionEvent } from "./klikaya.types";

function trackClick(eventName: Record<string, string>) {
  if (typeof kilkaya === "undefined") {
    return;
  }

  // eslint-disable-next-line no-undef -- kilkaya is defined in the global scope
  kilkaya.click(eventName);
}

export function useKilkayaClickEvent() {
  return { trackClick };
}

export function useKilkayaConversion(conversionEvent: ConversionEvent | null) {
  const consent = useCookiebotConsent();

  useEffect(() => {
    if (
      consent.submitted &&
      conversionEvent !== null &&
      typeof kilkaya !== "undefined"
    ) {
      // eslint-disable-next-line no-undef -- kilkaya is defined in the global scope
      kilkaya.pageview({
        cntTag: conversionEvent,
        conversion: 1,
        nopv: 1,
      });
    }
  }, [consent, conversionEvent]);
}

export function useKilkayaPageView(
  referrer: string | null,
  currentURL: string,
) {
  const { model: user } = useUser();
  const { trackingData } = useTrackingData();
  const consent = useCookiebotConsent();

  useEffect(() => {
    if (trackingData === null || !consent.submitted) {
      return;
    }
    // eslint-disable-next-line functional/immutable-data -- kilkaya reads from this variable
    window.k5aMeta = {
      author: trackingData.contentAuthor?.map((author) => author) ?? null,
      cntTag: null,
      consent: consent.marketing ? 1 : 0,
      contentTag: null,
      description: trackingData.description,
      image: trackingData.imageUrlPath,
      login: user.loggedIn ? 1 : 0,
      modifiedtime: trackingData.contentModifiedTime,
      paid: trackingData.pageRestrictedType === "paid" ? 1 : 0,
      paywall: getPaywallType(trackingData.pageRestrictedType, user.hasAccess),
      publishtime: trackingData.contentPublishTime,
      referrer,
      section: trackingData.sectionName,
      subscriber: user.hasAccess ? 1 : 0,
      subsection: null,
      tag: null,
      title: document.title,
      type: getPageType(trackingData.pageType, currentURL),
      url: document.URL,
    };
    document.dispatchEvent(new Event("K5A:SpaReady"));
  }, [consent, trackingData]);
}

function getPaywallType(pageRestrictedType: string, hasAccess: boolean) {
  switch (pageRestrictedType) {
    case "paid":
      return hasAccess ? "open" : "hard";
    case "free":
      return "open";
    default:
      return "open";
  }
}

function getPageType(pageType: string, currentURL: string) {
  const path = new URL(decodeURIComponent(currentURL)).pathname;
  switch (path) {
    case "/":
      return "frontpage";
    case archivePageLink:
      return "archive";
    case latestPageLink:
      return "latest";
    default:
      switch (pageType) {
        case "art":
          return "article";
        case "sec":
          return "section";
        default:
          return "website";
      }
  }
}
